<template>
	<v-app>
		<v-app-bar app color="#fff" dark>
		<!-- <v-app-bar app color="#080d46" dark> -->
			<lzy-Header></lzy-Header>
		</v-app-bar>

		<v-main style="font-size: 14px;">
			<router-view />
		</v-main>
		
	</v-app>
</template>
<style lang="scss">
	@media (min-width: 1904px) {
		.container {
			max-width: 1185px;
		}
	}
	.v-app-bar.v-app-bar--fixed {
		z-index: 1000 !important;
	}
</style>
<script>
	import Header from "./components/Header2.vue";
	// import Header from "./components/Header.vue";
	export default {
		name: "App",
		components: {
			"lzy-Header": Header,
		},
		data: () => ({
			//
		}),
	};
</script>