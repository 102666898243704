import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home2.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/FEELWORLD_Light',
    name: 'FEELWORLD_Light',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FEELWORLD_Light.vue')
  },
  {
    path: '/FEELWORLD_LIVE_PLUS',
    name: 'FEELWORLD_LIVE_PLUS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FEELWORLD_LIVE_PLUS.vue')
  },
  {
    path: '/Feelworld_Wireless',
    name: 'Feelworld_Wireless',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Feelworld_Wireless.vue')
  },
  {
    path: '/FEELWORLD',
    name: 'FEELWORLD',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FEELWORLD.vue')
  },
  {
    path: '/FeelworldLive',
    name: 'FeelworldLive',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FeelworldLive.vue')
  },
  {
    path: '/VEVOR',
    name: 'VEVOR',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VEVOR.vue')
  },
  {
    path: '/ANDYCINE',
    name: 'ANDYCINE',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ANDYCINE.vue')
  },
  {
    path: '/detail/:type',
    name: 'detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
  },
  {
    path: '/TimerseAd',
    name: 'TimerseAd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TimerseAd.vue')
  },
  
]

const router = new VueRouter({
  routes
})

export default router
