<template>
	<v-container class="d-flex justify-md-space-between" style="font-size: 16px;">
		<v-row class="d-flex justify-md-start">
			<v-col class="col-md-2">
				<v-img class="shrink mr-2" contain src="../assets/images/LOGO2.png" transition="scale-transition" width="120" />
			</v-col>
			<v-col class="d-flex align-center col-md-10" style="padding: 0">
				<div class="d-flex align-center headerItem" @click="goPage('/')">
					<span class='headerSpan'>主页</span>
				</div>
				<v-hover class="pa-4" v-slot="{hover}" :open-delay='headerOpenDelay'>
					<div class="d-flex align-center headerItem">
						<span class='headerSpan' :style="hover ? 'color: #2196F3;' : ''">产品与服务</span>
						<v-expand-transition :elevation="10">
							<div v-if="hover" class="d-flex justify-center v-card--reveal">
								<v-row no-gutters class="container">
									<v-col cols="4">
										<div class="d-flex justify-start flex-column" style="height: 100%;">
											<v-subheader :inset="true" light>
												摄影与录像
											</v-subheader>
											<v-list class="flex-grow-1" light style="border-right: 1px solid #eee;">
												<v-list-item-group>
													<v-list-item v-for="(item, i) in menu.menu1_1" :key="i" class='litItem' @click="goPage(item.url)">
														<v-list-item-icon>
															<v-icon v-text="item.icon" color="#2196F3"></v-icon>

														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title v-text="item.text" class='itemTitle'></v-list-item-title>
															<v-list-item-subtitle v-text="item.script"></v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</div>
										<!-- <v-divider vertical light></v-divider> -->
									</v-col>
									<v-col cols="4">
										<div class="d-flex justify-start flex-column" style="height: 100%;">
											<v-subheader :inset="true" light>
												软件开发工具
											</v-subheader>
											<v-list class="flex-grow-1" light  style="border-right: 1px solid #eee;" >
												<v-list-item-group>
													<v-list-item v-for="(item, i) in menu.menu1_2" :key="i" class='litItem'  @click="goPage(item.url)">
														<v-list-item-icon>
															<v-icon v-text="item.icon" color="#2196F3"></v-icon>
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title v-text="item.text" class='itemTitle'></v-list-item-title>
															<v-list-item-subtitle v-text="item.script">
															</v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</div>
									</v-col>
									<v-col cols="4">
										<div class="d-flex justify-start flex-column" style="height: 100%;">
											<v-subheader :inset="true" light>
												工具
											</v-subheader>
											<v-list class="flex-grow-1" light style="border-right: 1px solid #eee;">
												<v-list-item-group>
													<v-list-item v-for="(item, i) in menu.menu1_3" :key="i" class='litItem'  @click="goPage(item.url)">
														<v-list-item-icon>
															<v-icon v-text="item.icon" color="#2196F3"></v-icon>
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title v-text="item.text" class='itemTitle'></v-list-item-title>
															<v-list-item-subtitle v-text="item.script">
															</v-list-item-subtitle>
														</v-list-item-content>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</div>
									</v-col>
								</v-row>
							</div>
						</v-expand-transition>
					</div>
				</v-hover>
				<div class="d-flex align-center headerItem" @click="goPage('/about')">
					<span class='headerSpan'>关于我们</span>
				</div>
			</v-col>
		</v-row>
		<!-- <div class="d-flex justify-md-start">
			<v-btn color="#2196F3"  style="width: 120px;margin: 10px 0 0 10px;">联系我们</v-btn> 
		</div> -->

	</v-container>
</template>

<style>
	.headerSpan {
		cursor: pointer;
		color: #2196F3;
		margin-right: 20px;
	}
	.headerItem {
		/* cursor: pointer; */
		align-self: stretch;
	}
	.litItem:hover .itemTitle {
		color: #2196F3;
	}
	.listBorder {
		border-right: 1px solid #eee;
	}
	.v-card--reveal {
		position: absolute;
		top: 62px;
		left: 0;
		width: 100%;
		align-items: center;
		justify-content: center;
		background: #fff;
		color: #666;
	}
</style>
<script>
	export default {
		name: "Header",
		data: function () {
			return {
				headerOpenDelay: 200,
				menu: {
					menu1_1: [
						{
							icon: "mdi-monitor-screenshot",
							text: "FEELWORLD",
							url: '/FEELWORLD'
						}, 
						{
							icon: "mdi-video-vintage",
							text: "FEELWORLD WIRLESS",
							url: '/Feelworld_Wireless'
						}, 
					],
					menu1_2: [
						{
							icon: "mdi-light-flood-up",
							text: "FEELWORLD Light",
							url: '/FEELWORLD_Light'
						}, 
						{
							icon: "mdi-microsoft-xbox-controller-battery-medium",
							text: "FEELWORLD LIVE PLUS",
							url: '/FEELWORLD_LIVE_PLUS'
						}, 
					],
					menu1_3: [
						{
							icon: "mdi-gamepad-square",
							text: "FEELWORLDLIVE",
							url: '/FeelworldLive'
						}, 
						{
							icon: "mdi-projector-screen-outline",
							text: "VEVOR",
							url: '/VEVOR'
						}, 
						{
							icon: "mdi-format-wrap-square",
							text: "ANDYCINE",
							url: '/ANDYCINE'
						}, 
						
					],
				}
				
			};
		},
		methods: {
			goPage(page){
				this.$router.push(page)
			},
		}
	};
</script>