var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"oneBox"},[_c('div',{staticStyle:{"width":"1150px"}},[_c('v-carousel',{attrs:{"cycle":"","hide-delimiter-background":""}},[_c('v-carousel-item',{attrs:{"src":require("../assets/images/1.jpg"),"cover":""}}),_c('v-carousel-item',{attrs:{"src":require("../assets/images/2.jpg"),"cover":""}}),_c('v-carousel-item',{attrs:{"src":require("../assets/images/3.jpg"),"cover":""}}),_c('v-carousel-item',{attrs:{"src":require("../assets/images/4.jpg"),"cover":""}}),_c('v-carousel-item',{attrs:{"src":require("../assets/images/5.jpg"),"cover":""}})],1)],1)]),_vm._m(0),_c('div',{staticClass:"twoBox block-scene"},[_c('div',{staticClass:"twoContent"},[_c('div',{staticClass:"boxTitle"},[_vm._v("我们的产品")]),_c('div',{staticClass:"listBox"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"listItem",on:{"click":function($event){return _vm.goPage('/FEELWORLD_Light')}}},[_c('div',{staticClass:"imgBox oneImg",class:hover ? 'bigSize':''}),_c('p',{staticClass:"imgScript"},[_vm._v("FEELWORLD Light")]),_c('p',{staticClass:"imgScript2"},[_vm._v("蓝牙灯光控制app")])])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"listItem",on:{"click":function($event){return _vm.goPage('/FEELWORLD_LIVE_PLUS')}}},[_c('div',{staticClass:"imgBox threeImg",class:hover ? 'bigSize':''}),_c('p',{staticClass:"imgScript"},[_vm._v("FEELWORLD LIVE PLUS")]),_c('p',{staticClass:"imgScript2"},[_vm._v("L4视频混合切换台控制app")])])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"listItem",on:{"click":function($event){return _vm.goPage('/FeelworldLive')}}},[_c('div',{staticClass:"imgBox sixImg",class:hover ? 'bigSize':''}),_c('p',{staticClass:"imgScript"},[_vm._v("FeelworldLive")]),_c('p',{staticClass:"imgScript2"},[_vm._v("L2视频混合切换台控制app")])])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"listItem",on:{"click":function($event){return _vm.goPage('/FEELWORLD')}}},[_c('div',{staticClass:"imgBox eightImg",class:hover ? 'bigSize':''}),_c('p',{staticClass:"imgScript"},[_vm._v("FEELWORLD")]),_c('p',{staticClass:"imgScript2"},[_vm._v("提词器app")])])]}}])})],1)])]),_c('lzy-Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"threeBox block-scene"},[_c('div',{staticClass:"threeContent",staticStyle:{"width":"1250px"}},[_vm._v(" 厦门鹭播科技有限公司成立于2021年，是视瑞特的全资子公司，专注于科技推广与应用服务的创新型企业。 "),_c('p'),_vm._v(" 公司秉承科技驱动发展的理念，致力于为客户提供全方位的电子产品销售、广播影视设备销售以及广播电视传输设备销售等服务。 "),_c('p'),_vm._v(" 同时，鹭播科技还涉足集成电路芯片及产品销售、电子元器件批发与零售等领域，以满足不同行业对高科技产品的需求。 ")])])}]

export { render, staticRenderFns }