<template>
	<div class="footBox">
		<div class="foot1Box">
			<v-container class="logoBox">
					<v-img class="shrink mr-2" contain src="../assets/images/LOGO.png" transition="scale-transition" width="120" />
			</v-container>
		</div>
		<div class="foot2Box" style="background: #080d46;">
			<v-row>
				<v-col cols='12' class="copyRightBox d-flex justify-center flex-column align-center">
					<div class="mb-1">© 2021-2024 厦门鹭播科技股份有限公司 版权所有 <a class="saveLink" href="https://www.loobro.com/privacy/policy.html" target="_blank">隐私政策</a> </div>
					<div class="d-flex justify-start">
						<a href="https://beian.miit.gov.cn" style="text-decoration: none;margin-right: 20px;" target="_blank">闽ICP备2022012666号</a>
						<a href="https://beian.mps.gov.cn/#/query/webSearch" style="text-decoration: none;" target="_blank">闽公网安备35020302036203号</a>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Footer",
		data: function () {
			return {
			};
		},
		mounted(){
		},
		methods: {
			
		}
	};
</script>
<style lang='scss'>
	.footBox {
		.foot1Box {
			background: #fff;
				text-align: center;
			.logoBox {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
		.footItem {
			cursor: pointer;
			span {
				color: #fff;
				font-size: 12px;
			}
		}
		.copyRightBox {
			color: #fff;
			font-size: 12px;
			span {
				cursor: pointer;
				margin-right: 20px;
			}
			span:last-child {
				margin-right: 0px;
			}
			.mb-1 {
				position: relative;
				width: 100%;
				text-align: center;
				.saveLink {
					position: absolute;
					right: 20px;
					top: 0px;
					color: #fff;
					text-decoration: none;
				}
			}
		}
	}
</style>